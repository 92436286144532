
import React, { useState, useEffect } from 'react';
import { Link as ScrollLink, Element } from 'react-scroll';
import headerBackground from '../images/fond-header.png';
import decoheader from '../images/deco-header.png';
import decocommentmarche from '../images/deco-commentmarche.png';
import imgdeco from '../images/img-deco-enfants.png';
import Icopersonne from '../images/Ico-personne.png';
import iconuage from '../images/ico-nuage.png';
import icobouclier from '../images/ico-bouclier.png';
import fondpreinscription from '../images/fond-preinscription.png';
import logolenedesign from '../images/logo-lenedesign.png';
import logoewmcarre from '../images/logo-ewm-carre.png';
import logoadie from '../images/logo_adie.png';
import icotelephone from '../images/ico-telephone.png';
import icogeolo from '../images/ico-geoloc.png';
import icoemail from '../images/ico-email.png';
import logofooterLAPLI from '../images/logo-footerLAPLI.png';
import logofacebook from '../images/logo-facebook.png';
import logoinstagram from '../images/logo-instagram.png';
import Navbar from '../component/navbar';
import fondfooter from '../images/fond-footer.png'
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import contenuCGU from '../component/dataCGU';

const Accueil = ({ confirmationMessage }) => {
  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  const [AttconfirmationMessage, setAttconfirmationMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [modalColor, setModalColor] = useState('');
  const location = useLocation(); // Access location object

  const [modelCGUColor, setmodelCGUColor] = useState('#ff6a2f')
  const openmodelCGU = () => {
    document.getElementById('my_modal_4').showModal();
  }

  const closeModelCGU = (e) => {
    e.preventDefault()
    const modal = document.getElementById('my_modal_4');
    if (modal) {
      modal.close();
    }
  };

  const closeModel = (e) => {
    e.preventDefault();
    const modal = document.getElementById('my_modal_3');
    if (modal) {
      modal.close();
      // Réinitialiser l'URL à http://localhost:3000 sans paramètres de requête
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  };
  const closeModelL = (e) => {
    e.preventDefault();
    const modal = document.getElementById('my_modal_5');
    if (modal) {
      modal.close();
      // Réinitialiser l'URL à http://localhost:3000 sans paramètres de requête
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  };

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    code_postal: ''
  });

  const resetForm = () => {
    setFormData({
      name: '',
      email: '',
      code_postal: ''
    });
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Call reCAPTCHA
      const recaptchaToken = await executeRecaptcha();
      const formDataWithToken = {
        ...formData,
        recaptchaToken: recaptchaToken
      };

      const response = await axios.post('https://lapli:lazpdr56TY78@lapli.app/admin/public/api/registerr', formDataWithToken);
      // const response = await axios.post('http://localhost:8000/api/registerr', formDataWithToken);
      if (response.status === 200) {
        setAttconfirmationMessage(response.data.message);
        setModalColor('text-green-600 border-green-600');
        resetForm();
        navigate('/');

      } else {
        setAttconfirmationMessage(response.data.message);
        setModalColor('text-red-600 border-red-600 ');

      }
      resetForm();
    } catch (error) {
      setAttconfirmationMessage(error.response?.data?.message || 'An error occurred. Please try again.');
      setModalColor('text-red-600 border-red-600 ');

    } finally {
      setLoading(false);
      document.getElementById('my_modal_3').showModal();
    }

  };
  const getHoverBorderColorClass = (modalColor) => {
    if (modalColor.includes('green')) {
      return 'hover:border-green-600  bg-white text-green-600';
    } else if (modalColor.includes('red')) {
      return 'hover:border-red-600 bg-white text-red-600';
    } else {
      return 'hover:border-gray-800 bg-white';
    }
  };
  const executeRecaptcha = async () => {
    return new Promise((resolve, reject) => {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute("6LdycOopAAAAAOIfWy0lLtsv81qP3FFmfWYFAExF", { action: "homepage" })
          .then(token => {
            resolve(token);
          })
          .catch(error => {
            reject(error);
          });
      });
    });
  };

  useEffect(() => {
    // Add reCaptcha script
    const script = document.createElement("script");
    script.src = "https://www.google.com/recaptcha/api.js?render=6LdycOopAAAAAOIfWy0lLtsv81qP3FFmfWYFAExF";
    document.body.appendChild(script);
    // Clean up function
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.get('success')) {
      setAttconfirmationMessage('Inscription confirmée, merci !');
      setModalColor('text-green-600 ');
      document.getElementById('my_modal_5').showModal();
    }
  }, [location]);

  return (
    <section className='h-[100%] flex flex-col lg:gap-5'>
      <Element name="Accueil" >
        <div className="bg-cover bg-center" style={{ backgroundImage: `url(${headerBackground})` }}>
          <Navbar />
          <div className="flex justify-center flex-col">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 w-[98%] mx-auto">
              <div className='h-auto flex flex-col'>
                <div className='lg:pl-24 mt-1'>

                  <p className='flex justify-center lg:justify-start lg:mt-24 md:text-[30px] sm:text-[28px]  lg:text-[30px] text-[27px] font-bold'>
                    L'<span className='lg:text-[#ff2500] text-[#fff] font-bold'>appli</span>cation qui
                  </p>
                  <p className='flex justify-center lg:justify-start md:text-[30px] sm:text-[28px] lg:text-[30px] text-[27px] font-bold'>
                    protège vos <span className='text-[#17b61e] px-1 font-bold'>enfants</span>!
                  </p>
                </div>
                <div className='lg:px-24 px-5 text-center pt-5'>
                  <p className='text-[16px] text-justify lg:pt-[20px]'>
                    <span className='lg:text-[#ff6a2f] text-[#fff] font-bold text-[20px]'>LAPLi</span> surveille, détecte, prévient et filtre les écrans à partir d'un algorithme par IA en backstage afin de protéger vos enfants des dangers et dérives du monde connecté.
                  </p>
                </div>
                <div className='lg:pl-24 mt-7 justify-center lg:justify-start flex'>
                  <ScrollLink to="comment-ca-marche" smooth={true} duration={500}>
                    <button className='bg-[#00c355] px-10 text-white py-2 text-sm rounded-full'>En Savoir Plus</button>
                  </ScrollLink>
                </div>
              </div>
              <div className='h-auto'>
                <img
                  src={decoheader}
                  className="lg:h-[545px] h-auto w-auto"
                  alt="Logo"
                />
              </div>
            </div>
          </div>
        </div>
      </Element>
      {/* my_modal_3 */}
      <div className=" flex justify-center items-center">
        <div className="absolute top-[74rem] left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <dialog id="my_modal_3" className=" -top-[12rem] modal">
            <div className={`modal-box w-10/12 sm:w-10/12 md:w-10/12 lg:w-8/12 xl:w-8/12 2xl:w-8/12 lg:h-32 flex items-center justify-center bg-white ${modalColor}`}>
              <form method="dialog    ">
                <button type="button" onClick={closeModel}
                  className={`btn btn-md btn-circle border border-${modalColor}  cursor-pointer btn-ghost  absolute lg:right-2  right-1 top-1 lg:top-2 text-[16px]  ${getHoverBorderColorClass(modalColor)}`}>
                  ✕</button>
              </form>
              <p className="font-bold text-[17px] pt-5  sm:text-[16px]  md:text-[17px]  lg:text-[18px]  xl:text-[18px]  2xl:text-[18px] text-center items-center">{AttconfirmationMessage}</p>
            </div>
          </dialog>
        </div>
      </div>
      {/* my_modal_5 */}
      <div className=" flex justify-center items-center">
        <div className="absolute top-[74rem] left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <dialog id="my_modal_5" className=" -top-[12rem] modal">
            <div className={`modal-box w-10/12 sm:w-10/12 md:w-10/12 lg:w-8/12 xl:w-8/12 2xl:w-8/12 lg:h-32 flex items-center justify-center bg-white ${modalColor}`}>
              <form method="dialog  ">
                <button type="button" onClick={closeModelL} className={`btn btn-md btn-circle border border-${modalColor}  cursor-pointer btn-ghost  absolute right-1  top-1 text-[16px]`}>✕</button>
              </form>
              <p className="font-bold text-[17px] pt-5  sm:text-[16px]  md:text-[17px]  lg:text-[18px]  xl:text-[18px]  2xl:text-[18px] text-center items-center">{AttconfirmationMessage}</p>
            </div>
          </dialog>
        </div>
      </div>
      {/* MODAL CGU */}
      <div className="w-[400px] flex justify-center items-center">

        <dialog id="my_modal_4" className="modal">
          <div className="modal-box w-[94%]  p-2 lg:max-w-5xl border-2 border-[#ff2500]">
            <div className="modal-action  ">
              <form method="dialog   ">
                <div className="p-1 overflow-auto h-[30rem]  scrollbar scrollbar-track-[#ffd9d6]  scrollbar-thumb-[#ff0940] " dangerouslySetInnerHTML={{ __html: contenuCGU }} />
                <div className='flex justify-center items-center'>
                  <button className=' btn bg-[#00c355] px-10 text-white py-2 text-sm rounded-full' onClick={closeModelCGU} >FERMER</button>

                </div>
              </form>
            </div>
          </div>
        </dialog>
      </div>
      <Element name="pre-inscription" className="bg-center" style={{ backgroundImage: `url(${fondpreinscription})` }}>
        <div className="grid grid-cols-1 lg:grid-cols-2 w-[98%] mx-auto">
          <div className='flex flex-col text-black justify-center items-center'>
            <p className='text-center lg:px-20 px-4 pt-4 lg:pt-10 lg:text-[22px] text-[18px] font-normal'>
              Enregistrez-vous pour être avertis de la sortie<br /> de l'application pour la rentrée 2024/2025
            </p>
            <img
              src={imgdeco}
              className="pt-3  h-auto lg:block hidden"
              alt="Logo"
              width="400px"
            />
          </div>
          <div className='flex flex-col'>
            <p className='text-center lg:px-20 px-4 pt-4 lg:pt-6 lg:text-[26px] text-[20px] text-[#ffc4ab] font-bold'>Pré-inscription</p>
            <form onSubmit={handleSubmit} className='flex justify-center w-[100%] py-5 items-center flex-col gap-4'>
              <input
                className='bg-white rounded-full lg:w-[55%] md:w-[60%] w-[80%] p-3 pl-7'
                name='name'
                value={formData.name}
                onChange={handleChange}
                placeholder='Nom' />
              <input
                name='email'
                value={formData.email}
                onChange={handleChange}
                className='bg-white rounded-full lg:w-[55%] md:w-[60%] w-[80%] p-3 pl-7'
                placeholder='Email' />
              <input
                name='code_postal'
                value={formData.code_postal}
                onChange={handleChange}
                className='bg-white rounded-full lg:w-[55%] md:w-[60%] w-[80%] p-3 pl-7' placeholder='Code postal ' />
              <div className="g-recaptcha" data-sitekey="_reCAPTCHA_site_key_" data-size="invisible"></div>

              <button type='submit' className='bg-[#00c355] px-12 text-white py-2 text-sm rounded-full'>ENVOYER</button>
            </form>
            {message && <p>{message}</p>}

          </div>
        </div>
      </Element>
      <Element name="comment-ca-marche">
        <div className="flex justify-center flex-col">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 w-[98%] mx-auto">
            <div className='h-auto'>
              <img
                src={decocommentmarche}
                className="lg:h-[545px] h-auto w-auto"
                alt="Logo"
              />
            </div>
            <div className='h-auto flex flex-col mx-auto'>
              <div className='mt-7'>
                <p className='flex justify-center lg:justify-start md:text-[30px] sm:text-[28px] lg:text-[30px] text-[#17b61e] text-[27px] font-bold'>Comment ça marche ?</p>
              </div>
              <div className='mt-7 flex flex-col gap-1   mx-auto'>
                <div className='flex flex-col lg:flex-row  justify-center items-center  gap-3 text-justify mx-auto p-1'>
                  <img
                    src={Icopersonne}
                    className="h-[120px] w-[120px]"
                    alt="Logo"
                  />
                  <div className='flex justify-center items-center'>
                    <p className='text-md lg:text-sm text-justify lg:pr-2 xl:pr-24 2xl:pr-24 md:pr-4 items-center font-medium'>
                      <span className='text-[#ff0000] font-bold px-2'>LAPLi</span>Surveille l'activité sur l'écran de l'appareil de l'enfant et analyse moyennant un algorithme d'intelligence artificielle et une base de connaissances en backstage les contenus (textes, vidéos, photos, échanges sur les réseaux sociaux...).
                    </p>
                  </div>
                </div>
                <div className='flex flex-col lg:flex-row  justify-center items-center  gap-3.5 text-justify mx-auto p-2'>
                  <img
                    src={iconuage}
                    className="h-[115px] w-[115px]"
                    alt="Logo"
                  />
                  <div className=' flex justify-center items-center'>
                    <p className='text-md lg:text-sm text-justify lg:pr-2 xl:pr-24 2xl:pr-24 md:pr-4 items-center font-medium'>
                      <span className='text-[#ff0000] font-bold px-2'>LAPLi</span>Détecte si l'activité sur l'appareil contient des dérives et dangers nocifs pour l'enfant comme la drogue, la pornographie, la violence, le harcèlement, la pédophilie, les jeux d'argent, le tabac, l'alcool...
                    </p>
                  </div>
                </div>
                <div className='flex flex-col lg:flex-row  justify-center items-center  gap-4 text-justify mx-auto p-3'>
                  <img
                    src={icobouclier}
                    className="h-[110px] w-[110px]"
                    alt="Logo"
                  />
                  <div className=' flex justify-center items-center'>
                    <p className='text-md lg:text-sm text-justify lg:pr-2 xl:pr-24 2xl:pr-24 md:pr-4 items-center font-medium'>
                      <span className='text-[#ff0000] font-bold px-2'>LAPLi</span>Prévient le parent immédiatement via une alerte sur son application et propose un mode de filtrage de l'écran de l'enfant en cas de détection d'une activité dangereuse. LAPLi s'assure que le parent soit le seul à pouvoir désactiver ce mode de filtrage.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Element>
      <Element name="contact">
        <div className="bg-center bg-cover h-[24rem] relative" style={{ backgroundImage: `url(${fondfooter})` }}>
          <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 w-[100%]  h-full">

            <div className="flex flex-col justify-center items-center lg:items-start  text-white text-xs gap-2 lg:mt-40 mt-14 lg:ml-32">
              <p className=' text-xs font-medium'>Développement et infogérance EASY WEB MOBILE</p>
              <p className=' text-xs font-medium'>Design par LENE Design</p>
              <p className=' text-xs font-medium'>Co-financé par l'ADIE</p>
              <div className="flex flex-row gap-4">
                <Link to='https://easywebmobile.fr/'  target="_blank" rel="noopener noreferrer" className='items-center flex justify-center'>
                  <img src={logoewmcarre} className="lg:w-12 w-9 h-auto" alt="Logo EWM" /></Link>
                <Link  to='https://www.adie.org'  target="_blank" rel="noopener noreferrer" className='items-center flex justify-center'>
                  <img src={logoadie} className="lg:w-12 w-9 h-auto " alt="Logo ADIE" />
                </Link>

                <img src={logolenedesign} className="lg:w-12 w-9 h-auto" alt="Logo LENE Design" />
              </div>
            </div>

            <div className="flex flex-row  lg:gap-5 sm:justify-evenly md:justify-evenly  justify-evenly lg:items-center md:items-center text-white text-xs  lg:mt-28 ">
              <div className="flex flex-col gap-2 md:mt-20 mt-3 lg:mt-4 ">
                <span className="flex gap-1 items-center">
                  <img src={icotelephone} className="lg:w-12 lg:h-12  sm:w-8 sm:h-8 md:w-12 md:h-12 w-8 h-8" alt="Icône Téléphone" />
                  <p className="font-medium lg:text-sm  md:text-sm sm:text-sm  text-[11px]  text-center ">+33 638 506 283</p>
                </span>
                <span className="flex flex-row  gap-2 items-center">
                  <img src={icogeolo} className="lg:w-12 lg:h-12  sm:w-8 sm:h-8 md:w-12 md:h-12 w-8 h-8" alt="Icône Géolocalisation" />
                  <span className='gap-2 flex  lg:flex-row flex-col'>
                    <Link className="font-medium lg:text-sm md:text-sm sm:text-sm  text-[12px]">hello@lapli.app</Link>
                    <Link to='https://lapli.app'  target="_blank" rel="noopener noreferrer" className="font-medium lg:text-sm md:text-sm sm:text-sm  text-[12px]">https://lapli.app</Link>
                  </span>
                </span>
                <span className="flex gap-2 items-center">
                  <img src={icoemail} className="lg:w-12 lg:h-12  sm:w-8 sm:h-8 md:w-12 md:h-12 w-8 h-8" alt="Icône Email" />
                  <span className="flex lg:flex-col flex-row gap-1">
                    <p className="font-medium lg:text-sm md:text-sm sm:text-sm  text-[12px]">3 Place Mailho</p>
                    <p className="font-medium lg:text-sm md:text-sm sm:text-sm  text-[12px]">65380 Bénac</p>
                  </span>
                </span>
              </div>
              <div className="flex flex-col lg:flex-row md:mt-14  mt-5 lg:mt-4 gap-2">
                <div className="flex gap-3 items-center">
                  {/* <Link to="https://web.facebook.com/laplifrance/?_rdc=1&_rdr"><img src={logofacebook} className="lg:w-12 lg:h-12  sm:w-8 sm:h-8 md:w-12 md:h-12 w-8 h-8 cursor-pointer" alt="Logo Facebook" />
                  </Link> */}
                  <Link to="https://web.facebook.com/laplifrance/?_rdc=1&_rdr" target="_blank" rel="noopener noreferrer">
                    <img src={logofacebook} className="lg:w-12 lg:h-12  sm:w-8 sm:h-8 md:w-12 md:h-12 w-8 h-8 cursor-pointer" alt="Logo Facebook" />
                  </Link>

                  <Link to='https://www.instagram.com/_lapli_?igsh=dmVvbnFscTF1MGhm&utm_source=qr'  target="_blank" rel="noopener noreferrer">   <img src={logoinstagram} className="lg:w-12 lg:h-12  sm:w-8 sm:h-8 md:w-12 md:h-12 w-8 h-8 cursor-pointer" alt="Logo Instagram" /></Link>
                </div>
                <div className='flex flex-col items-center gap-1'>
                  <ScrollLink to="Accueil"
                    className="font-semibold  cursor-pointer text-[11px]  text-start md:text-sm sm:text-sm lg:text-center lg:text-sm ">Accueil</ScrollLink>
                  <ScrollLink to="pre-inscription"
                    className="font-semibold  cursor-pointer text-[11px]  text-start md:text-sm sm:text-sm lg:text-center lg:text-sm">Pré-inscription</ScrollLink>
                  <button className="font-semibold text-[11px]  text-start md:text-sm sm:text-sm lg:text-center lg:text-sm" onClick={openmodelCGU}>CGU / RGPD</button>

                </div>
              </div>
            </div>


            <span className="absolute  top-8 lg:top-14 font-medium xs:left-[40%] left-[43%] transform -translate-x-1/2 text-center lg:text-sm text-xs text-[#85605c]">
              <img src={logofooterLAPLI} className='h-10 lg:h-14 w-auto' />
            </span>
            <p className="absolute bottom-2 font-medium xs:left-[12%] left-1/2 transform -translate-x-1/2 text-center lg:text-sm text-xs text-[#85605c]">
              @2024 All rights reserved Easy Web Mobile

            </p>
          </div>

        </div>
      </Element>

    </section>
  );
};

export default Accueil;
